import { useEffect, useRef, useState } from 'react';
import { convertFtToCm, covertCmToFt, onePnInKg } from './calculations.helper';
import { HeightWeightUnits } from '@/constants';

export const useCreateUpdateCustomerForm = ({ setFieldValue, values }) => {
  const ftInputRef = useRef<HTMLInputElement>();
  const inInputRef = useRef<HTMLInputElement>();
  const cmInputRef = useRef<HTMLInputElement>();
  const weightInputRef = useRef<HTMLInputElement>();
  const ageInputRef = useRef<HTMLInputElement>();
  const emailInputRef = useRef<HTMLInputElement>();

  const [wasFirstRender, setWasFirstRender] = useState(false);

  useEffect(() => {
    setFieldValue('height', convertFtToCm(values.feet, values.inch));
  }, [values.feet, values.inch]);

  useEffect(() => {
    if (wasFirstRender) {
      if (values.heightWeightUnit === HeightWeightUnits.LbsFtInches) {
        const lbWeightValue = values.weight * onePnInKg;

        setFieldValue('weight', values.weight === null ? null : lbWeightValue);
        const [feet, inch] = covertCmToFt(values.height);
        setFieldValue('feet', feet);
        setFieldValue('inch', inch);

        ftInputRef.current.inputMode = 'numeric';
        inInputRef.current.inputMode = 'numeric';
      } else {
        const kgWeightValue =
          values.weight / onePnInKg < 30 ? 30 : values.weight / onePnInKg;

        setFieldValue('weight', values.weight === null ? null : kgWeightValue);
        cmInputRef.current.inputMode = 'numeric';
        setFieldValue('height', convertFtToCm(values.feet, values.inch));
      }
    }
  }, [values.heightWeightUnit]);

  useEffect(() => {
    ftInputRef.current.inputMode = 'numeric';
    inInputRef.current.inputMode = 'numeric';
    weightInputRef.current.inputMode = 'numeric';
    ageInputRef.current.inputMode = 'numeric';
    if (emailInputRef.current) {
      emailInputRef.current.inputMode = 'email';
    }
    setWasFirstRender(true);
  }, []);

  return {
    ftInputRef,
    inInputRef,
    cmInputRef,
    weightInputRef,
    ageInputRef,
    emailInputRef
  };
};
