export enum ScanStatus {
  Canceled = 'canceled',
  Complete = 'complete',
  Failed = 'failed',
  WaitingForData = 'waiting for data'
}

export enum HeightWeightUnits {
  KgCm = 'Kg & Cm',
  LbsFtInches = 'Lb & Ft/Inch'
}
